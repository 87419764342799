<template>
	<div>
		<div class="row mb-3">
			<div class="col-12">
				<h1><em :class="{'far fa-id-card pinkslip': document.document_type=='pinkslip', 'far fa-file-pdf': document.document_type!='pinkslip'}"></em> {{document.title}}</h1>
			</div>
		</div>

		<div v-if="document.document_id > 0 && !error">
			<div class="text-center mb-3" v-if="document.document_id > 0">
				<div v-if="loadedRatio < 1"><em class="fas fa-circle-notch fa-spin fa-3x"></em><br>Loading Document...</div>
				<pdf v-show="loadedRatio == 1" :src="'https://api.mitchellwhale.com/api/' + document.document_type + '/' + document.slug" :rotate="rotate" @progress="loadedRatio = $event" @error="pdfError" ref="pdfViewer"></pdf>
			</div>

			<div class="text-center mb-3">
				<a :href="'https://api.mitchellwhale.com/api/' + document.document_type + '/' + document.slug" class="button" target="_blank">
					<img :src="retina('/images/button.png')" style="width: 213px; height: auto;" />
					<span><em class="fas fa-file-pdf"></em> Download</span>
				</a>
				<a href="" @click.prevent="print()" class="button">
					<img :src="retina('/images/button.png')" style="width: 213px; height: auto;" />
					<span><em class="fas fa-print"></em> Print</span>
				</a>
				<span v-show="document.document_type == 'pinkslip'">
					<a :href="'https://api.mitchellwhale.com/api/passkit/' + document.slug" target="_blank" class="button"><img :src="retina('/images/applewallet.png')" style="width: 213px; height: auto;" /></a>
					<a :href="'https://api.mitchellwhale.com/api/passkit/' + document.slug" target="_blank" class="button">
						<img :src="retina('/images/button.png')" style="width: 213px; height: auto;" />
						<span>Add to <em class="fab fa-google" style="color: rgb(83, 158, 244)"></em>oogle*</span>
					</a>
				</span>
			</div>

			<p class="text-center">* A wallet app capable of opening .pkpass files is required for Android devices. We recommend <a href="https://play.google.com/store/apps/details?id=com.attidomobile.passwallet&hl=en" target="_blank">PassWallet</a>.</p>
		</div>

		<div v-if="error" class="alert alert-danger text-center" v-html="error"></div>
	</div>
</template>

<script>
	import pdf from 'vue-pdf';

export default
{
	name: 'Document',
	props: ["session_id", "logged_in"],
	components:
	{
		pdf
	},
	data: function()
	{
		return {
			document: {},
			rotate: 0,
			loadedRatio: 0,
			error: false
		}
	},
	mounted: function()
	{
		if(!this.logged_in)
		{
			return;
		}

		this.loadDocument();
	},
	methods:
	{
		loadDocument: function()
		{
			var vm = this;
			vm.error = false;

			this.CORS("GET", "/document/" + vm.$route.params.document_id, null,
			function(response)
			{
				vm.document = response;
			}, function(response)
			{
				vm.error = "<h2>Error Loading Document</h2><p>" + response.responseText + "</p>";
			})
		},
		print: function()
		{
			this.$refs.pdfViewer.print(100);
		},
		pdfError: function(err)
		{
			this.loadedRatio = 0;

			if(typeof err == "undefined" || err == null)
			{
				this.error = "<h2>Error Loading Document</h2><p>An unknown error has occurred. Please reload this page to try again.</p><p>If you continue to experience this problem, please contact us in reference to an \"unknown error\" error while loading document ID " + this.document.document_id + ".";
			}
			else if(err.name == "MissingPDFException")
			{
				this.error = "<h2>Error Loading Document</h2><p>Our document server may be temporarily unavailable. Please try again shortly (just reload this page).</p><p>If you continue to experience this problem, please contact us in reference to document ID " + this.document.document_id + ".";
			}
			else if(err.name == "InvalidPDFException")
			{
				this.error = "<h2>Error Loading Document</h2><p>The PDF generated appears to be invalid. Please reload this page to try again.</p><p>If you continue to experience this problem, please contact us in reference to document ID " + this.document.document_id + ".";
			}
			else
			{
				this.error = "<h2>Error Loading Document</h2><p>An unhandled error has occurred. Please reload this page to try again.</p><p>If you continue to experience this problem, please contact us in reference to a \"" + err.name + "\" error while loading document ID " + this.document.document_id + ".";
			}
		}
	}
}
</script>

<style>
	.button
	{
		position: relative;
		display: inline-block;
		width: 213px;
		height: 66px;
		line-height: 66px;
		margin: 0 5px;
		padding: 0;
	}
	.button img
	{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}
	.button span
	{
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		z-index: 2;
		font-size: 1.5em;
		color: #fff;
	}
</style>{
  "message": "Missing PDF \"https://api.mitchellwhale.com/api/pdf/812a1bb50fd2dd6333657f2330a406a6f2bf84b2d09f01db49c3d56de19af432\".",
  "name": "MissingPDFException"
}